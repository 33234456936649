import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import PortableText from "../components/portableText";

export const query = graphql`
  query ResearchPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawResearchPageContent(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const ResearchPage = ({ data = {} }) => {
  const researchPageContent = data.site?._rawResearchPageContent;
  return (
    <>
      <SEO title="Research Database" />
      {researchPageContent && (
        <div className="prose dark:prose-invert prose-lg md:py-12">
          <PortableText blocks={researchPageContent} />
        </div>
      )}
    </>
  );
};

export default ResearchPage;
